@import "https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700;800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Manrope:wght@400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400&display=swap";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.inset-0 {
  inset: 0;
}

.bottom-\[10\%\] {
  bottom: 10%;
}

.left-10 {
  left: 2.5rem;
}

.top-10 {
  top: 2.5rem;
}

.top-\[10\%\] {
  top: 10%;
}

.order-first {
  order: -9999;
}

.order-last {
  order: 9999;
}

.m-5 {
  margin: 1.25rem;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-3 {
  margin-right: .75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.size-16 {
  width: 4rem;
  height: 4rem;
}

.size-5 {
  width: 1.25rem;
  height: 1.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.min-h-16 {
  min-height: 4rem;
}

.w-10 {
  width: 2.5rem;
}

.w-14 {
  width: 3.5rem;
}

.w-3 {
  width: .75rem;
}

.w-3\/6 {
  width: 50%;
}

.w-5 {
  width: 1.25rem;
}

.w-full {
  width: 100%;
}

.min-w-16 {
  min-width: 4rem;
}

.max-w-screen-xl {
  max-width: 1280px;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.place-content-between {
  place-content: space-between;
}

.content-between {
  align-content: space-between;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.gap-8 {
  gap: 2rem;
}

.self-end {
  align-self: flex-end;
}

.overflow-hidden {
  overflow: hidden;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-md {
  border-radius: .375rem;
}

.border {
  border-width: 1px;
}

.border-0 {
  border-width: 0;
}

.bg-black-full {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-fblack {
  --tw-bg-opacity: 1;
  background-color: rgb(37 43 46 / var(--tw-bg-opacity));
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(0 140 130 / var(--tw-bg-opacity));
}

.bg-secondary {
  --tw-bg-opacity: 1;
  background-color: rgb(229 243 242 / var(--tw-bg-opacity));
}

.bg-tertiary {
  --tw-bg-opacity: 1;
  background-color: rgb(225 237 251 / var(--tw-bg-opacity));
}

.bg-transparent {
  background-color: #0000;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.p-8 {
  padding: 2rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pb-2 {
  padding-bottom: .5rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-center {
  text-align: center;
}

.font-manrope {
  font-family: Manrope;
}

.font-montserrat {
  font-family: Montserrat;
}

.font-poppins {
  font-family: Poppins;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-light {
  font-weight: 300;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.leading-7 {
  line-height: 1.75rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-loose {
  line-height: 2;
}

.text-fblack {
  --tw-text-opacity: 1;
  color: rgb(37 43 46 / var(--tw-text-opacity));
}

.text-gblack {
  --tw-text-opacity: 1;
  color: rgb(110 110 110 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(0 140 130 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -4px #0000001a;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:root {
  font-size: 16px;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: Lexend;
}

.text-title-huge {
  font-size: 5rem;
}

.text-title-large {
  font-size: 3.5rem;
}

.text-title-medium {
  font-size: 3rem;
}

.text-title-normal {
  font-size: 2rem;
}

.text-title-small {
  font-size: 1.5rem;
}

.text-para-huge {
  font-size: 1.25rem;
}

.text-para-large {
  font-size: 1.125rem;
}

.text-para-medium {
  font-size: 1rem;
}

.text-para-normal {
  font-size: .875rem;
}

.text-para-small {
  font-size: .625rem;
}

.cursorFollowerFirst {
  mix-blend-mode: normal;
  z-index: -999;
  pointer-events: none;
  background: radial-gradient(circle at 45% 45%, #fff570 0, #0000 40%);
  width: 100rem;
  height: 100rem;
  transition: transform .3s;
  position: fixed;
  transform: translate(-50%, -50%);
}

.cursorFollowerSecond {
  mix-blend-mode: normal;
  z-index: -999;
  pointer-events: none;
  background: radial-gradient(circle at 60% 60%, #7bcabe 0, #0000 40%);
  width: 100rem;
  height: 100rem;
  transition: transform .3s;
  position: fixed;
  transform: translate(-50%, -50%);
}

.cursorFollowerThird {
  transform: translate(-50%, -50%);
  -webkit-transform: transform .3s;
  mix-blend-mode: normal;
  z-index: -999;
  pointer-events: none;
  background: radial-gradient(circle at 40% 48%, #f7d9de 0, #0000 40%);
  width: 100rem;
  height: 100rem;
  transition: transform .3s;
  position: fixed;
}

#banner-section {
  top: 90px;
}

.banner-area {
  height: 100vh;
  min-height: 800px;
}

.banner-button-img {
  width: 36px;
  height: auto;
}

#header-section {
  padding: 40px 0;
}

#about-us-section {
  margin-bottom: 60px;
  padding-top: 20px;
}

.services-area {
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 20px;
}

.our-work {
  margin-bottom: 60px;
  padding-top: 20px;
}

#whatwedo-slider {
  transition: left .5s;
  display: flex;
  position: relative;
}

.info-card {
  background-color: #fff;
  border-radius: 30px;
  min-width: 345px;
  margin-right: 30px;
  padding: 3rem 2rem;
}

.info-card:last-child {
  margin-right: 0 !important;
}

.info-card-image {
  border-radius: 30px;
  padding: 30px;
}

.info-card-icon {
  width: 33px;
  height: auto;
}

.reasons-to-choose-us {
  margin-bottom: 20px;
  padding-top: 20px;
}

.reasons-to-choose-us-title {
  padding-top: 60px;
  padding-bottom: 60px;
}

.reasons-to-choose-us-body {
  gap: 100px;
}

.width-controlled {
  max-width: 558px;
  line-height: 2.75rem;
}

#portfolio-section {
  margin-bottom: 100px;
  padding-top: 20px;
}

.portfolio-gallery {
  gap: 0 70px;
  width: 100%;
}

.portfolio-card {
  margin-bottom: 70px;
}

.why-not-hire-us-section {
  margin-bottom: 60px;
}

#hire-slider {
  --d: 20s;
  overflow: hidden;
}

.gradient-hire-us {
  mask: linear-gradient(90deg, #0000, #000 4% 96%, #0000);
}

#hire-slider-inner {
  flex-direction: row;
  width: max-content;
  padding-block: 1rem;
  display: flex;
}

.animate-hire-us {
  animation: scroll var(--d) linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-340px * var(--card-count)));
  }
}

.poster-card-inner {
  top: 340px;
  left: 40px;
}

.poster-card {
  margin-right: 40px;
}

#our-team-section {
  margin-bottom: 90px;
  padding-top: 20px;
}

#ourteam-slider {
  transition: left .5s;
  display: flex;
  position: relative;
}

.team-slider-inner {
  min-width: 345px;
  margin-right: 35px;
}

.team-slider-inner:last-child {
  margin-right: 0;
}

.teams-card {
  margin-bottom: 35px;
}

.subscribe {
  border-radius: 16px;
  margin-bottom: 100px;
  padding: 8px;
}

.subscribe-image {
  width: 150px;
  height: 150px;
  margin-right: 20px;
}

#testimonial-section {
  margin-bottom: 100px;
  padding-top: 20px;
}

#testimonial-slider {
  margin-bottom: 16px;
  padding-left: 5px;
  padding-right: 5px;
  transition: left .5s;
  display: flex;
  position: relative;
}

.testimonial_card {
  background-color: #fff;
  border-radius: 40px;
  min-width: 500px;
  margin-right: 30px;
}

.testimonial_card:last-child {
  margin-right: 0 !important;
}

#get-in-touch-section {
  margin-bottom: 60px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.inputdiv {
  background-color: #fff9;
  border-width: 1px;
  border-color: #e0e0e0;
  width: 100%;
  height: 60px;
  margin-top: 16px;
  margin-bottom: 16px;
  font-family: Montserrat;
}

.find-us-select {
  appearance: none;
  background: url("data:image/svg+xml,<svg width=\"12\" height=\"6\" viewBox=\"0 0 12 6\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\"><path d=\"M6 6L12 0L0 -5.24537e-07L6 6Z\" fill=\"black\"/></svg>") right 15px top 50% no-repeat;
}

@media screen and (width <= 768px) {
  .text-title-huge {
    font-size: 2.25rem;
  }

  .text-title-large {
    font-size: 2rem;
  }

  .text-title-normal {
    font-size: 1.5rem;
  }

  .text-title-small, .text-para-huge {
    font-size: 1rem;
  }

  .text-title-medium {
    font-size: 2rem;
  }

  .text-para-medium {
    font-size: .75rem;
  }

  .width-section {
    margin-left: 20px;
    margin-right: 20px;
  }

  .banner-area {
    height: 80vh;
  }

  .banner-button-img {
    width: 24px;
    height: auto;
  }

  .portfolio-gallery {
    column-gap: 35px;
    width: 100%;
  }

  .testimonial_card {
    min-width: 80vw !important;
  }

  .map {
    height: 450px;
  }

  .team-slider-inner {
    min-width: 150px;
    margin-right: 35px;
  }
}

@media screen and (width >= 768px) and (width <= 1024px) {
  .text-title-huge {
    font-size: 3.5rem;
  }

  .text-title-large {
    font-size: 2.125rem;
  }

  .text-title-normal {
    font-size: 1.5rem;
  }

  .text-title-small {
    font-size: 1rem;
  }

  .text-title-medium {
    font-size: 2rem;
  }

  .text-para-huge {
    font-size: 1.125rem;
  }

  .text-para-medium {
    font-size: .75rem;
  }

  .width-section {
    margin-left: 40px;
    margin-right: 40px;
  }

  .banner-button-img {
    width: 24px;
    height: auto;
  }

  .portfolio-gallery {
    column-gap: 35px;
    width: 100%;
  }

  .portfolio-card {
    margin-bottom: 40px;
  }

  .map {
    height: 450px;
  }
}

@media screen and (width >= 1024px) {
  .width-section {
    margin-left: 70px;
    margin-right: 70px;
  }

  .subscribe {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }

  .gradient-background-fifth {
    background: linear-gradient(to right, #fff0 70%, #008c82 30%);
  }

  .map {
    width: 50%;
  }
}

.placeholder\:text-gray-400::placeholder {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.even\:mt-10:nth-child(2n) {
  margin-top: 2.5rem;
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 140 130 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(0 140 130 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.focus\:outline-none:focus {
  outline-offset: 2px;
  outline: 2px solid #0000;
}

.focus\:ring-0:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

@media not all and (width >= 1024px) {
  .max-lg\:mb-8 {
    margin-bottom: 2rem;
  }

  .max-lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .max-lg\:items-center {
    align-items: center;
  }

  .max-lg\:rounded-xl {
    border-radius: .75rem;
  }

  .max-lg\:bg-black-transparent {
    background-color: #09080877;
  }

  .max-lg\:text-white {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
}

@media not all and (width >= 768px) {
  .max-md\:mb-8 {
    margin-bottom: 2rem;
  }

  .max-md\:block {
    display: block;
  }

  .max-md\:hidden {
    display: none;
  }

  .max-md\:w-full {
    width: 100%;
  }

  .max-md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media not all and (width >= 640px) {
  .max-sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (width >= 640px) {
  .sm\:m-auto {
    margin: auto;
  }

  .sm\:mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:mr-2 {
    margin-right: .5rem;
  }

  .sm\:h-9 {
    height: 2.25rem;
  }

  .sm\:w-full {
    width: 100%;
  }

  .sm\:leading-6 {
    line-height: 1.5rem;
  }
}

@media (width >= 768px) {
  .md\:col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:flex {
    display: flex;
  }

  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }

  .md\:place-self-end {
    place-self: end;
  }

  @media not all and (width >= 1024px) {
    .md\:max-lg\:grid-cols-2 {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
}

@media (width >= 1024px) {
  .lg\:order-1 {
    order: 1;
  }

  .lg\:order-2 {
    order: 2;
  }

  .lg\:mx-40 {
    margin-left: 10rem;
    margin-right: 10rem;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:mr-16 {
    margin-right: 4rem;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:space-x-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(2rem * var(--tw-space-x-reverse));
    margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .lg\:py-2\.5 {
    padding-top: .625rem;
    padding-bottom: .625rem;
  }
}
/*# sourceMappingURL=index.31761938.css.map */
